<template>
  <el-dialog
    id="SelectFileDialog"
    :title="title"
    :visible.sync="dialogShow"
    center
    modal
    top="5vh"
    :close-on-click-modal="false"
    width="360px"
    close-on-press-escape
    append-to-body
    class="my-dialog abow_dialog"
    @opened="onShow"
    destroy-on-close
    @keydown.enter.native="onCommit"
  >
    <el-form ref="SelectFileForm" @submit.native.prevent="onSubmit" v-loading="loading">
      <el-upload
        action=""
        class="upload-demo"
        list-type="text"
        :on-change="onFileSelect"
        :before-remove="onFileSelect"
        :limit="1"
        :file-list="fileList"
        show-file-list
        accept=".json"
        :auto-upload="false"
      >
        <el-button size="small" type="success">{{ $t("Оберіть файл") }}</el-button>
        <div slot="tip" class="el-upload__tip">{{ $t("Підтримуються файли *.json") }}</div>
      </el-upload>
    </el-form>

    <span slot="footer" class="dialog-footer">
      <el-button native-type="submit" type="success" @click="onCommit" class="apply-button" :disabled="!fileList || !fileList.length">{{
        $t("Застосувати")
      }}</el-button>
      <el-button type="text" @click="dialogShow = false" class="cancel-button">{{ $t("Відмінити") }}</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { bus } from "@/main";
import { _ } from "vue-underscore";

export default {
  name: "SelectFile",
  props: ["busEvent"],
  components: {},
  data() {
    var that = this;
    return {
      loading: true,
      dialogShow: false,
      title: $tt("Завантаження файлів"),
      operation: null,
      fileList: [],
    };
  },

  methods: {
    onCommit() {
      if (this.fileList.length == 1) {
        this.loading = true;
        this.dialogShow = false;
        this.$emit("commit", { file: this.fileList[0], operation: this.operation });
      } else {
        this.$message.error($tt("Перевірте введені дані!"));
      }
    },
    onShow() {
      this.$refs.SelectFileForm.resetFields();
      this.fileList = [];
      this.loading = false;
    },
    onFileSelect(file, fileList) {
      this.fileList = fileList.filter((file) => file.raw.type == "application/json");
    },
  },
  created() {
    bus.$on(this.busEvent + ".show", (data) => {
      this.title = (data && data.title) || this.title;
      this.dialogShow = true;
      this.operation = data.operation;
    });
    bus.$on(this.busEvent + ".hide", () => {
      this.dialogShow = false;
    });
    bus.$on(this.busEvent + ".clear", () => {
      this.fileList = [];
    });
  },
  computed: {},
};
</script>
<style lang="scss">
#SelectFileDialog {
  .el-dialog {
    max-height: 240px !important;
  }
}
</style>
